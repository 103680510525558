<template>
  <div class="SeatMonitoring page">
    <div class="top">
      <div>
        <a-input
          placeholder="分机号码"
          style="width: 140px; margin-right: 20px"
        ></a-input>
        <a-select
          style="width: 140px; margin-right: 20px"
          placeholder="分机状态"
        >
          <a-select-option value="jack">Jack</a-select-option>
          <a-select-option value="lucy">Lucy</a-select-option>
        </a-select>
        <a-button type="primary">
          <template #icon><SearchOutlined /></template>
          Search
        </a-button>
      </div>
      <div class="top-right">
        <div class="item">
          <label>0</label>
          <span>通话中</span>
        </div>
        <div class="item">
          <label>0</label>
          <span>通话中</span>
        </div>
        <div class="item">
          <label>0</label>
          <span>通话中</span>
        </div>
        <div class="item">
          <label>0</label>
          <span>通话中</span>
        </div>
      </div>
    </div>
    <div>
      <a-button type="primary" style="margin-right: 15px">闲置</a-button>
      <a-button type="primary">置忙</a-button>
    </div>
    <div class="box-content">
      <div class="box">
        <div class="green one"></div>
        <span>空闲</span>
      </div>
      <div class="box">
        <div class="green two"></div>
        <span>振铃中</span>
      </div>
      <div class="box">
        <div class="green three"></div>
        <span>通话</span>
      </div>
      <div class="box">
        <div class="green four"></div>
        <span>示忙</span>
      </div>
      <div class="box">
        <div class="green five"></div>
        <span>下线</span>
      </div>
    </div>
    <div class="robot-content">
      <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>
       <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>
       <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>
       <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>
       <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>
       <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>
       <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>
       <div class="robot-item">
        <img src="@/assets/head_default.png" />
        <div>
            <div><label>账号:</label><span>1002</span></div>
            <div><label>分机:</label><span>1002</span></div>
            <div><label>姓名:</label><span>技术运维1</span></div>
            <div><label>部门:</label><span>管理层</span></div>
        </div>
        <a-checkbox class="checked"></a-checkbox>
      </div>

    </div>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue';
export default {
  name: "SeatMonitoring",
   components: {
    SearchOutlined,
  },
};
</script>

<style scoped>
.SeatMonitoring {
  padding: 15px;
}
.top {
  display: flex;
  justify-content: space-between;
}
.item {
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 0 40px;
}
.item label {
  color: #3896e6;
  font-size: 20px;
}
.top-right {
  display: flex;
  align-items: center;
}
.box-content {
  display: flex;
  margin-top: 15px;
}
.box {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.green {
  width: 15px;
  height: 15px;
  /* background-color: #3896e6; */
  border-radius: 4px;
  margin-right: 4px;
}
.one {
  background-color: #1dcd95;
}
.two {
  background-color: #f9b03d;
}
.three {
  background-color: #1e9fff;
}
.four {
  background-color: #ff6666;
}
.five {
  background-color: #cccccc;
}
.robot-content{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.robot-item{
    display: flex;
    padding: 10px;
    border: 5px solid #ccc;
    width: 195px;
    align-items: center;
    margin-right: 20px;
    position: relative;
    margin-bottom: 20px;
    /* box-sizing: border-box; */
}
.robot-item img{
    width: 50px;
    height: 50px;
    margin-right: 15px;
}
.robot-item span{
    color: rgb(173, 165, 165);
    margin-left: 5px;
}
.checked{
    position: absolute;
    left: -6px;
    top: -8px;
}
</style>